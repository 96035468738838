import React, { useEffect } from 'react'
import ProductScreenBanner from '../assets/product-screen-banner.png'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { ReactComponent as FraudFiltering } from '../assets/fraud-filtering.svg';
import { ReactComponent as RankCandidates } from '../assets/rank-candidates.svg';
import { ReactComponent as CompareCandidates } from '../assets/compare-candidates.svg';
import { ReactComponent as AiAssistant } from '../assets/ai-assistant.svg';
import { ReactComponent as Visibility } from '../assets/visibility.svg';
import { ReactComponent as CustomizedFiltering } from '../assets/customized-filtering.svg';
import { ReactComponent as ValidationChecks } from '../assets/validation-checks.svg';
import { FaRobot } from "react-icons/fa";
import { TypeAnimation } from 'react-type-animation';
import '../styles/nliten-talent-scout/nliten-talent-scout.scss'

const cardRecords = {
  fraudFiltering: {
    icon: <FraudFiltering className='real-icon' />,
    heading: 'Automated filtering of fraud resumes',
    content: 'various data points are applied through an AI infused system to detect and rank candidates on the authenticity of the resume information.',
  },
  rankCandidates: {
    icon: <RankCandidates className='real-icon' />,
    heading: 'Ranks candidates on mandatory skills required in the JD',
    content: 'Recruiters can select and customize the skills required (both mandatory and preferred skills), which the system will use for ranking the candidates.',
  },
  compareCandidates: {
    icon: <CompareCandidates className='real-icon' />,
    heading: 'Compare candidates based on their resume and authenticity',
    content: 'shortlisted candidates can be compared based on their ranking of skills as per resume and the authenticated skill information generated to identify high quality candidates.',
  },
  aiAssistant: {
    icon: <AiAssistant className='real-icon' />,
    heading: 'Deeper analysis with AI assistant (chatbot)',
    content: 'recruiters, interviewers and clients can perform analysis to determine readiness of the candidate for the role.',
  },
  visibility: {
    icon: <Visibility className='real-icon' />,
    heading: 'High visibility of candidate analysis and selection to client',
    content: 'Clients can view system generated ranking and comparison, authenticity scores and interview analysis of the selected candidate in addition to the real time AI assistant.',
  },
  customizedFiltering: {
    icon: <CustomizedFiltering className='real-icon' />,
    heading: 'Creating customized filtering of candidates, specific to client',
    content: 'Advanced recruiters can create custom requirement and filters specific to a client and role.',
  },
  validationChecks: {
    icon: <ValidationChecks className='real-icon' />,
    heading: 'Validation checks on degrees and certifications',
    content: 'system can be customized to validate degrees and certifications where the education institute provides online capability through APIs.',
  }
};

function NlitenTalentScout() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='product-one-component'>
      <div className='image-main'>
        <img className='about-img' src={ProductScreenBanner} alt='ProductScreenBanner' />
      </div>
      <Container fluid>
        <Row className='container-two  g-0'>
          <Col md={12} sm={12} xs={12}>
            <p className='heading-two-content'>“NLiten Talent Scout” is an AI infused IT recruiting product that filters out unauthentic candidates and shortlists them based on multiple factors relevant to the job description. It’s unique features include filtering out inauthentic candidates, matching scores across multiple skill level, AI-driven chatbots for deeper analysis and providing an “NLiten Authenticity Score” based on the candidates integrity across multiple dimensions. Our initial tests have revealed that it can increase the recruiter’s efficiency nearly five times and doubles the hit rate, hence increasing the revenue potential for IT staffing companies tenfold for the same recruiting organizational construct.  It can be used in conjunction and integrated to the company’s existing recruiting software or work as a standalone capability for recruiters.</p>
          </Col>
          <Col md={12} sm={12} xs={12}>
            <p className='heading-three'>Differentiating Value Creation</p>
          </Col>
        </Row>
        <Row className='cards-main  g-0'>
          {
            Object.keys(cardRecords).map((key, index) => {
              return (
                <>
                  {
                    index == 3 ?
                      <Col xl={12} md={12} sm={12} xs={12} key={key}>
                        <Card>
                          <Card.Body className='card-two'>
                            <Row>
                              <Col xl={6} md={6} sm={12} xs={12} className='content-main'>
                                <p className='icon-main'>{cardRecords[key].icon}</p>
                                <p className='heading'>{cardRecords[key].heading}</p>
                                <p className='content'>{cardRecords[key].content}</p>
                              </Col>
                              <Col xl={6} md={6} sm={12} xs={12} className='input-main'>
                                <p className='message'>
                                  <TypeAnimation
                                    preRenderFirstString={true}
                                    sequence={[
                                      4500,
                                      'Brandon has 2 years of python, but the role requires 3 years.',
                                      5500,
                                      "",
                                      4500,
                                      'From Amy\'s experience, she has good communication and people management skills.',
                                      5500,
                                      "",
                                      4500,
                                      'Yes, Ganesh has worked with PyTorch and ETL at NLitenData for 1 year and 3 months.',
                                      5500,
                                      "",
                                    ]}
                                    speed={50}
                                    cursor={false}
                                    omitDeletionAnimation={true}
                                    style={{ fontSize: '20px' }}
                                    repeat={Infinity}
                                  />
                                </p>
                                <div className="input-container">
                                  <FaRobot className="icon" />
                                  <div>
                                  </div>
                                  <p className="input" >
                                    <TypeAnimation
                                      preRenderFirstString={true}
                                      sequence={[
                                        'What skills or experiences is this candidate missing for the role?',
                                        10000, // Wait till answer is done
                                        'What are some potential leadership qualities this candidate may have?',
                                        10000,
                                        'Has the candidate worked on projects involving both PyTorch and ETL together?',
                                        10000,
                                      ]}
                                      speed={50}
                                      omitDeletionAnimation={true}
                                      style={{ fontSize: '20px' }}
                                      repeat={Infinity}
                                    />
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                      :
                      <Col xl={4} md={4} sm={12} xs={12} key={key}>
                        <Card>
                          <Card.Body>
                            <p className='icon-main'>{cardRecords[key].icon}</p>
                            <p className='heading'>{cardRecords[key].heading}</p>
                            <p className='content'>{cardRecords[key].content}</p>
                          </Card.Body>
                        </Card>
                      </Col>
                  }
                </>
              )
            })}
        </Row>
      </Container>
    </div>
  )
}

export default NlitenTalentScout;
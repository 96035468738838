import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { ReactComponent as Logo } from '../assets/logo.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCaretDown } from "react-icons/fa";
import '../styles/header/header.scss'

function Header() {
  const [currentNav, setCurrentNav] = useState('home')
  const navigate = useNavigate();
  const location = useLocation();
  const width = window.innerWidth;

  // Synchronize currentNav with the current route path
  useEffect(() => {
    const path = location.pathname.slice(1); // Get the path without the leading '/'
    setCurrentNav(path || 'home'); // If path is empty, default to 'home'
  }, [location.pathname]);

  function handleHeaderNav(params) {
    navigate(`/${params}`)
    setCurrentNav(params)
    window.scrollTo(0, 0);
  }

  return (
    <Navbar expand="lg" className="navbar-main" collapseOnSelect>
      <Container fluid>
        <Navbar.Brand>
          <Logo
            style={{ width: "150px", height: "70px", fill: '#8d3d04', cursor: "pointer" }}
            onClick={() => { handleHeaderNav('home') }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
          </Nav>
          <div className="menus-main">
            <Nav.Link
              className={currentNav === 'home' ? 'menus-active' : "nav-link"}
              onClick={() => { handleHeaderNav('home') }}
              eventKey={1}
            >
              Home
            </Nav.Link>
            {
              width > 992 ?
                <div className="dropdown-container" >
                  <Nav.Link
                    style={{ width: "100px" }}
                    className={currentNav === 'product' || currentNav === 'nliten-talent-scout' ? 'menus-active' : "nav-link-2"}
                    onClick={() => handleHeaderNav('product')}
                    eventKey={2}
                  >
                    Product
                    <span><FaCaretDown className='icon' /></span>
                  </Nav.Link>
                  <div className="dropdown-menu">
                    <Nav.Link className='sub-button' onClick={() => handleHeaderNav('nliten-talent-scout')} eventKey={3}>
                      NLiten Talent Scout
                    </Nav.Link>
                  </div>
                </div>
                :
                <div className="dropdown-container" >
                  <Nav.Link
                    style={{ width: "100px" }}
                    className={currentNav === 'product' || currentNav === 'nliten-talent-scout' ? 'menus-active' : "nav-link-2"}
                  >
                    Product
                    <span><FaCaretDown className='icon' /></span>
                  </Nav.Link>
                  <div className="dropdown-menu">
                    <Nav.Link className='sub-button' onClick={() => handleHeaderNav('product')} eventKey={2}>
                      Product
                    </Nav.Link>
                    <Nav.Link className='sub-button' onClick={() => handleHeaderNav('nliten-talent-scout')} eventKey={3}>
                      NLiten Talent Scout
                    </Nav.Link>
                  </div>
                </div>
            }
            <Nav.Link
              className={currentNav === 'consulting' ? 'menus-active' : "nav-link"}
              onClick={() => { handleHeaderNav('consulting') }}
              eventKey={4}
            >
              Consulting
            </Nav.Link>
            <Nav.Link
              className={currentNav === 'industries' ? 'menus-active' : "nav-link"}
              onClick={() => { handleHeaderNav('industries') }}
              eventKey={5}
            >
              Industries
            </Nav.Link>
            <Nav.Link
              className={currentNav === 'team' ? 'menus-active' : "nav-link"}
              onClick={() => { handleHeaderNav('team') }}
              eventKey={6}
            >
              Team
            </Nav.Link>
            <Nav.Link
              className={currentNav === 'about' ? 'menus-active' : "nav-link"}
              onClick={() => { handleHeaderNav('about') }}
              eventKey={7}
            >
              About
            </Nav.Link>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;